import React from 'react'
import { Box } from '@mui/material'
import TableMobileCard from './table-mobile-card'
import './table-mobile.scss'

export const TenantScreeningTableMobile = ({
  data,
  onClickRentalSubmissionRow,
  eventSource,
  isAllowAcceptDeny,
  isAllowCarLease,
  onClickSetupRentPayment,
  onClickSetupLease,
  onOpenAssignPropertyModal,
  onOpenAcceptDenyModal,
  hidePropertyAddress,
}) => (
  <Box className="screening-table-mobile-container">
    {data?.map((rentalSubmission, index) => (
      <TableMobileCard
        key={index}
        index={index}
        rentalSubmission={rentalSubmission}
        hidePropertyAddress={hidePropertyAddress}
        eventSource={eventSource}
        isAllowAcceptDeny={isAllowAcceptDeny}
        isAllowCarLease={isAllowCarLease}
        onOpenAcceptDenyModal={onOpenAcceptDenyModal}
        onOpenAssignPropertyModal={onOpenAssignPropertyModal}
        onClickSetupLease={onClickSetupLease}
        onClickSetupRentPayment={onClickSetupRentPayment}
        onClickRentalSubmissionRow={onClickRentalSubmissionRow}
      />
    ))}
  </Box>
)
