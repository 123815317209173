/* eslint-disable camelcase */
import React from 'react'
import { Box } from '@mui/material'
import Tooltip from '@rentspree/component-2023.components.atoms.tooltip'
import { Typography } from '@rentspree/component-2023.components.atoms.typography'
import { Table } from '@rentspree/component-2023.components.organisms.table'
import { TableBody } from '@rentspree/component-2023.components.organisms.table-body'
import { TableCell } from '@rentspree/component-2023.components.organisms.table-cell'
import { TableHead } from '@rentspree/component-2023.components.organisms.table-head'
import { TableRow } from '@rentspree/component-2023.components.organisms.table-row'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { APP_STATUS } from 'containers/application/const'
import { CLICK_FROM } from 'containers/accept-deny/constants'
import { isRead } from 'legacy/helpers/credit-report'
import { getReportVoiceSpeech } from 'utils/screen-reader/voice-speech-generator'

import ActionDropdown from '../../action-dropdown'
import { generateApplicationProgress, generateSubmissionDate } from '../../helpers'

import { ScreeningTableCellApplicant } from '../table-cell-applicant'
import { ScreeningTableCellStatus } from '../table-cell-status'
import './table-desktop.scss'

export const TenantScreeningTableDesktop = ({
  data,
  isAllowAcceptDeny,
  isAllowCarLease,
  eventSource,
  onClickRentalSubmissionRow,
  onOpenAcceptDenyModal,
  onOpenAssignPropertyModal,
  onClickSetupLease,
  onClickSetupRentPayment,
  hidePropertyAddress,
}) => (
  <Table
    data-testid="screening-table-content"
    className="screening-table-container screening-table-desktop"
  >
    <TableHead>
      <TableRow id="applicationTableHeader">
        <TableCell key="applicant">
          <Box className="screening-table-header-cell">Applicant</Box>
        </TableCell>
        <TableCell key="status">
          <Box className="screening-table-header-cell">Status</Box>
        </TableCell>
        <TableCell key="submitted_at">
          <Box className="screening-table-header-cell">
            Submission date
            <Tooltip
              title="To keep information up to date, TransUnion reports expire after 30 days."
              placement="top"
            >
              <ErrorOutlineIcon />
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell key="actions">
          <Box className="screening-table-header-cell" justifyContent="right">
            Actions
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody className="screening-table-body-container">
      {data?.map((rental, index) => {
        const {
          _id: appId,
          status,
          submitted_at: submittedAt,
          email,
          firstName,
          lastName,
          agentType,
          credit_report: creditReport,
          screeningResult,
        } = rental
        const isReportUnread =
          [APP_STATUS.submitted.value, APP_STATUS.property_required.value].includes(status) &&
          !isRead(rental)
        const speechContent = getReportVoiceSpeech({
          firstName,
          lastName,
          email,
          status,
        })
        const applicationProgress = generateApplicationProgress(status, screeningResult?.result)
        const submissionDate = generateSubmissionDate(
          submittedAt,
          creditReport?.generated_at,
          creditReport?.verified_idma_at,
        )

        return (
          <TableRow
            className="screening-table-body-row"
            id={`applicationTableRow${index}`}
            data-testid={`application-table-row-${appId}`}
            key={appId}
            greyRow={!isReportUnread}
            tabIndex="0"
            onKeyPress={keyInput => {
              if (keyInput.key === 'Enter') {
                onClickRentalSubmissionRow(rental)
              }
            }}
            aria-label={speechContent}
            textBold={isReportUnread}
            onClick={() => onClickRentalSubmissionRow(rental)}
            disabled={false}
          >
            <TableCell id={`applicationItem${index}`}>
              <ScreeningTableCellApplicant
                rental={rental}
                hidePropertyAddress={hidePropertyAddress}
              />
            </TableCell>
            <TableCell>
              <ScreeningTableCellStatus applicationProgress={applicationProgress} />
            </TableCell>
            <TableCell align="left">
              <Typography variant="body">{submissionDate}</Typography>
            </TableCell>
            <TableCell align="right">
              <ActionDropdown
                rentalSubmission={rental}
                source={CLICK_FROM.TENANT_SCREENING}
                eventSource={eventSource}
                isAllowAcceptDeny={isAllowAcceptDeny(agentType)}
                isAllowCarLease={isAllowCarLease}
                onOpenAcceptDenyModal={onOpenAcceptDenyModal}
                onOpenAssignPropertyModal={onOpenAssignPropertyModal}
                onClickSetupLease={onClickSetupLease}
                onClickSetupRentPayment={onClickSetupRentPayment}
              />
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  </Table>
)
