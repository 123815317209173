import React, { useCallback, useMemo } from 'react'
import { useDisableFeature } from '@rentspree/feature-toggle'
import { buildPath } from '@rentspree/path'
import tracker from 'tracker'
import {
  EVENT_ACTION_DROPDOWN,
  EVENT_ACTION_DROPDOWN_CLICK_FROM,
  EVENT_TENANT_SCREENING,
  LEASE_AGREEMENTS,
  LISTING_MANAGEMENT_EVENT,
  TOGGLE_PROPERTY_FEATURE,
} from 'tracker/const'

import { AGENT_TYPES } from 'containers/tenant-screening/request/constants'
import { E_SIGNATURE_FEATURE_ID } from 'utils/feature-toggle/constants'
import { PropertyTableActions } from 'v3/components/property-table-actions'
import {
  ESIGN_DASHBOARD_SELECT_METHODS,
  PERMALINK_ACTIONS_ID,
  SINGLE_PROPERTY_APPLICATION_LIST,
} from '../../constants/route'
import { LISTING_MANAGEMENT_SOURCE } from '../../containers/constants'
import { SCREENING_METHODS } from '../../legacy/constants/screening-plan-consts'
import { createListingLink } from '../../containers/overview/constants'
const { SCREEN_FROM_CURRENT_PROPERTY } = EVENT_ACTION_DROPDOWN
const { PROPERTY_LIST } = EVENT_ACTION_DROPDOWN_CLICK_FROM

export const DropdownWithUseDisableFeature = props => {
  const { id, property, clickArchiveProperty, history, isHighlightScreenTenant, setIsHighlightScreenTenant, isNewUi } = props
  const { _id: propertyId } = property

  const { disableFeature, isReady } = useDisableFeature()
  const isDisabledTenantScreeningFeature = useMemo(
    () => !!property?.disabledFeatures?.tenantScreening,
    [property?.disabledFeatures?.tenantScreening],
  )
  const isESignatureEnabled = useMemo(
    () => isReady && disableFeature.isFeatureEnabled(E_SIGNATURE_FEATURE_ID),
    [isReady],
  )

  const clickMarketListing = useCallback(() => {
    tracker.trackEvent(LISTING_MANAGEMENT_EVENT.EVENT_NAME.CLICK_MARKET_LISTING, {
      click_from: PROPERTY_LIST,
      is_onboarding_partner_agent: isHighlightScreenTenant,
    })

    const listingManagementPath = buildPath(
      createListingLink(property.listing, { source: LISTING_MANAGEMENT_SOURCE.PROPERTY_LIST }),
      { propertyId },
    )
    history.push(listingManagementPath)
  }, [propertyId])

  const clickScreeningMethod = useCallback(() => {
    if (isDisabledTenantScreeningFeature) {
      tracker.trackEvent(TOGGLE_PROPERTY_FEATURE.viewReport, {
        is_onboarding_partner_agent: isHighlightScreenTenant,
      })
      history.push(
        buildPath(SINGLE_PROPERTY_APPLICATION_LIST, {
          propertyId,
        }),
      )
    } else {
      tracker.trackEvent(EVENT_TENANT_SCREENING.EVENT_NAME.START_SCREENING_REQUEST, {
        click_from: PROPERTY_LIST,
        is_onboarding_partner_agent: isHighlightScreenTenant,
      })

      tracker.trackEvent(SCREEN_FROM_CURRENT_PROPERTY, {
        click_from: PROPERTY_LIST,
        is_onboarding_partner_agent: isHighlightScreenTenant,
      })

      const agentType = AGENT_TYPES.LISTING_AGENT

      history.push(
        buildPath(
          PERMALINK_ACTIONS_ID,
          { propertyId },
          { screeningMethod: SCREENING_METHODS.EMAIL, agentType },
        ),
      )
    }
  }, [propertyId])

  const clickLeaseAgreement = useCallback(() => {
    tracker.trackEvent(LEASE_AGREEMENTS.CREATE_LEASE, {
      click_from: PROPERTY_LIST,
      is_onboarding_partner_agent: isHighlightScreenTenant,
    })

    const url = buildPath(ESIGN_DASHBOARD_SELECT_METHODS, { propertyId })
    window.location.assign(url)
  }, [propertyId])

  const items = [
    [
      {
        key: 'property-table-market-listing-action',
        label: 'Market listing',
        onClick: () => clickMarketListing(),
      },
      {
        key: 'property-table-email-action',
        label: isDisabledTenantScreeningFeature ? 'View Reports' : 'Screen tenants',
        onClick: () => clickScreeningMethod(),
      },
      {
        key: 'property-table-set-up-lease-action',
        label: 'Set up lease',
        hidden: !isESignatureEnabled,
        onClick: () => clickLeaseAgreement(),
      },
    ],
    [
      {
        key: 'property-table-archive-property-action',
        label: 'Archive property',
        onClick: () => clickArchiveProperty(propertyId),
      },
    ],
  ]

  return (
    <PropertyTableActions
      id={id}
      items={items}
      isHighlightScreenTenant={isHighlightScreenTenant}
      setIsHighlightScreenTenant={setIsHighlightScreenTenant}
      isNewUi={isNewUi}
    />
  )
}
