import React from 'react'
import B16 from '@rentspree/component-v2/dist/typography/b16'

import Typography from '@rentspree/component-2023.components.atoms.typography'
import { MODAL_STATUS } from './constants'

const { ARCHIVE, UNARCHIVE } = MODAL_STATUS

// [TECH-22068] TODO: clean up feature flag and use new ui
const Body = ({ type, isNewUiPropertyTable }) => {
  if (isNewUiPropertyTable) {
    switch (type) {
      case ARCHIVE:
        return (
          <Typography variant="body-medium">
            The listing page will remain visible to the public but will be shown as unavailable. The
            property will move to the archived properties section of your dashboard.
          </Typography>
        )
      case UNARCHIVE:
        return (
          <Typography variant="body-medium">
            This property will be active and move to the all properties section.
          </Typography>
        )
      default:
        return <Typography variant="body-medium">something went wrong, please try again</Typography>
    }
  } else {
    switch (type) {
      case ARCHIVE:
        return (
          <B16>
            The listing page will remain visible to the public but will be shown as unavailable. The
            listing will move to the Archived Listings section of your dashboard.
          </B16>
        )
      case UNARCHIVE:
        return <B16>The listing will be moved to the all listing page.</B16>
      default:
        return <div>something went wrong, please try again</div>
    }
  }
}

export default Body
