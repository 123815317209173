import Box from '@mui/material/Box'
import Button from '@rentspree/component-2023.components.atoms.button'
import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import Level1Template from '@rentspree/component-2023.components.templates.level-1-template'
import { useFeatureGate } from '@statsig/react-bindings'
import React from 'react'

import { CDN_URL } from 'env'
import tracker from 'tracker'
import { ZERO_STATE_SCREENING } from 'utils/feature-flag/constants'
import { EmptyStateLandingTemplate } from 'v3/components/templates/empty-state-landing'
import { useGetMyUserInvitation } from 'v3/hooks/use-get-my-invitations'

export const EMPTY_STATE_TEXTS = {
  DEFAULT_AGENT_NAME: 'Your agent',
  TITLE: 'hasn’t shared reports with you yet',
  DESCRIPTION: 'While you’re waiting, check out these great resources.',
}

export const CTAS = {
  LEFT: {
    TEXT: 'View sample report',
    URL: 'https://support.rentspree.com/en/a-completed-rentspree-application',
  },
  RIGHT: {
    TEXT: 'Read "Choosing tenants"',
    URL: 'https://www.rentspree.com/blog/how-to-choose-between-two-applicants',
  },
}

const ZERO_STATE_SCREENING_IMAGE_URL =
  'https://cdn.rentspree.com/static-files/zero-state/screening-page-empty-state-invited-ll.svg'

export const OnboardingLandlordTSEmptyState = ({ height, hideTitle = false }) => {
  const { data: userInvitation, isFetched: isUserInvitationFetched } = useGetMyUserInvitation()
  const { value: isZeroStateTenantScreeningEnabled } = useFeatureGate(ZERO_STATE_SCREENING)

  let agentName = EMPTY_STATE_TEXTS.DEFAULT_AGENT_NAME
  if (isUserInvitationFetched) {
    agentName =
      [userInvitation?.result?.inviter?.firstName, userInvitation?.result?.inviter?.lastName]
        .filter(Boolean)
        .join(' ') || EMPTY_STATE_TEXTS.DEFAULT_AGENT_NAME
  }

  const openNewTabToViewSampleReport = () => {
    if (isZeroStateTenantScreeningEnabled) {
      tracker.trackButtonClick({
        click_text: 'View sample report',
        click_url: CTAS.LEFT.URL,
        location: 'empty_state',
      })
    }
    window.open(CTAS.LEFT.URL, '_blank')
  }

  const openNewTabToChoosingTenants = () => {
    window.open(CTAS.RIGHT.URL, '_blank')
  }

  if (isZeroStateTenantScreeningEnabled) {
    return (
      <>
        {!hideTitle && (
          <Box>
            <Level1Template title="Screening" />
          </Box>
        )}
        <Box>
          <EmptyState
            title={`${agentName} hasn’t shared reports yet`}
            subTitle="While you wait, check out a sample report."
            image={
              <img
                src={ZERO_STATE_SCREENING_IMAGE_URL}
                alt="empty-state-screening-invited-landlord"
              />
            }
            secondaryCTA={
              <Button
                onClick={openNewTabToViewSampleReport}
                variant="text"
                color="secondary"
                className="underline"
              >
                View sample report
              </Button>
            }
          />
        </Box>
      </>
    )
  }

  return (
    <EmptyStateLandingTemplate
      height={height}
      testIdPrefix="onboarding-ts-empty-state"
      title={`${agentName} ${EMPTY_STATE_TEXTS.TITLE}`}
      description={`${EMPTY_STATE_TEXTS.DESCRIPTION}`}
      imageUrl={`${CDN_URL}/static-files/onboarding/report-preview.svg`}
      imgAlt="report-preview"
      leftCTA={
        <Button
          data-testid="onboarding-ts-empty-state-left-cta"
          variant="outlined"
          size="small"
          onClick={openNewTabToViewSampleReport}
        >
          {CTAS.LEFT.TEXT}
        </Button>
      }
      rightCTA={
        <Button
          data-testid="onboarding-ts-empty-state-right-cta"
          variant="outlined"
          size="small"
          onClick={openNewTabToChoosingTenants}
        >
          {CTAS.RIGHT.TEXT}
        </Button>
      }
    />
  )
}
