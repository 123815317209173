import { COLOR } from 'styles/settings'

export const CREATE_PROPERTY = '+ Create/select a property'
export const NO_SPECIFIC_PROPERTY = 'No specific property'

export const TABLE_HEADER = [
  { text: 'Property Address', value: 'property', class: 'text-left', align: 'left' },
  { text: 'Requested', value: 'total_requested', class: 'text-center', align: 'center' },
  { text: 'Completed', value: 'total_submitted', class: 'text-center', align: 'center' },
  { text: 'Shared', value: 'total_shared', class: 'text-center', align: 'center' },
  { text: 'Actions', value: '', class: 'text-center', align: 'right', minWidth: '70px' },
]

export const FILTER_PROPERTY_LIST_OPTIONS = [
  {
    label: 'All',
    value: 'all',
    iconColor: COLOR.blackText,
  },
  {
    label: 'Active',
    value: 'active',
    iconColor: COLOR.statusSuccess,
  },
  {
    label: 'Archived',
    value: 'archived',
    iconColor: COLOR.statusNeutral,
  },
]
