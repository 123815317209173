import Box from '@mui/material/Box'
import Button from '@rentspree/component-2023.components.atoms.button'
import StickyFooterTemplate from '@rentspree/component-2023.components.templates.sticky-footer-template'
import React from 'react'

import './screen-tenant-footer.scss'

export const ScreenTenantFooter = ({ handleAddScreenNewTenant, isMobile, noMarginTop }) => (
  <Box
    data-testid="screen-tenant-footer"
    className={`${!noMarginTop && 'screen-tenant-footer-space-reserved-container'}`}
  >
    <Box className="screen-tenant-footer-container">
      <StickyFooterTemplate>
        <Box className="screen-tenant-footer-cta-container">
          <Button
            variant="contained"
            size="small"
            fullWidth={isMobile}
            onClick={handleAddScreenNewTenant}
          >
            Screen tenant
          </Button>
        </Box>
      </StickyFooterTemplate>
    </Box>
  </Box>
)
