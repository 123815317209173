import React from 'react'

import { Typography } from '@rentspree/component-2023.components.atoms.typography'

import { Box } from '@mui/material'
import { COLOR } from 'styles/settings'
import { APPLICATION_PROGRESS_TEXT_COLOR } from '../constants'

export const ScreeningTableCellStatus = ({ applicationProgress }) => {
  const statusColor = APPLICATION_PROGRESS_TEXT_COLOR[applicationProgress] ?? COLOR.statusNeutral

  return (
    <Box className="screening-table-body-cell-status">
      <Typography variant="body" color={statusColor}>
        {applicationProgress}
      </Typography>
    </Box>
  )
}
