import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import toNumber from 'lodash/toNumber'
import findIndex from 'lodash/findIndex'
import indexOf from 'lodash/indexOf'
import includes from 'lodash/includes'
import { buildPath, query } from '@rentspree/path'
import Box from '@mui/material/Box'
import { useFeatureGate } from '@statsig/react-bindings'
import { Level2Template } from '@rentspree/component-2023.components.templates.level-2-template'

import { L2_TABLE_PAGINATION_PER_PAGE } from 'env'
import tracker from 'tracker'
import {
  ESIGN_DASHBOARD_SELECT_METHODS,
  PERMALINK_ACTIONS_ID,
  PROPERTY,
  SINGLE_APPLICATION,
} from 'constants/route'
import {
  EVENT_ACTION_DROPDOWN,
  EVENT_ACTION_DROPDOWN_CLICK_FROM,
  EVENT_REQUEST_SCREENING,
  EVENT_REPORT,
  LEASE_AGREEMENTS,
  RENT_PAYMENT,
  EVENT_TENANT_SCREENING,
} from 'tracker/const'
import { COMBINED_AGREEMENT_IDV_ADDRESS } from 'utils/feature-flag/constants'
import useMobile from 'hooks/use-mobile'

import { CenterContent, Loading } from 'components/layout/main'

import Breadcrumb2023 from 'containers/breadcrumb-2023'
import * as ERRORS from 'constants/error-messages'
import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import { isAllowAcceptDeny } from 'containers/reports/helper'
import PropertyInnerTabs from 'containers/property-inner-tabs'
import {
  FEATURE,
  FEATURE_STATUS,
  SOURCE,
} from 'containers/property/toggle-property-feature/constants'

import { FailedToFetch } from 'legacy/components/layout/failed-to-fetch'
import { withTracker } from 'legacy/containers/with-tracker'
import {
  APP_NOT_SUBMIT_STATUS,
  FILTER_APP_PROGRESS_OPTIONS,
} from 'legacy/constants/application-consts'
import applicationListConnect from 'legacy/containers/application/application-list-connect'
import { SCREEN_NEW_TENANT } from 'legacy/constants/request-const'
import { POST_LOG_IN_ADVERTISE_MODAL_GROUP } from 'legacy/containers/application/const'
import DeactivatePropertyModal from 'legacy/components/modal/deactivate-property-modal'
import ReactivatePropertyModal from 'legacy/components/modal/reactivate-property-modal'
import { SCREENING_METHODS } from 'legacy/constants/screening-plan-consts'

import './property-applications-list.scss'
import { useGetMyUserInvitation } from 'v3/hooks/use-get-my-invitations'
import { OnboardingLandlordTSEmptyState } from 'v3/components/organisms/onboarding-ll-ts-empty-state'
import { TenantScreeningFilterBar } from 'components/tenant-screening-table/filter-bar'
import { TenantScreeningTable } from 'components/tenant-screening-table/table/table'
import { TenantScreeningPagination } from 'components/tenant-screening-table/pagination'
import { SCREENING_TABLE_FILTER_BAR_OPTIONS } from 'components/tenant-screening-table/constants'
import { PropertyApplicationZeroState } from './landing'
import { ScreenTenantFooter } from './screen-tenant-footer'

const { SCREEN_FROM_CURRENT_PROPERTY, DEACTIVATE_PROPERTY, REACTIVATE_PROPERTY } =
  EVENT_ACTION_DROPDOWN
const { APPLICATION_LIST } = EVENT_ACTION_DROPDOWN_CLICK_FROM

const DEFAULT_QUERY = {
  page: 1,
  perPage: L2_TABLE_PAGINATION_PER_PAGE,
  filter: { status: FILTER_APP_PROGRESS_OPTIONS[0].value },
  sort: {},
}

export const PropertyApplicationsList = ({
  applicationList,
  property,
  history,
  disabledFeatures,
  isAllowCarLease,
  location,
  actions,
  match,
  profile,
  showDeactivatePropertyModal,
  showReactivatePropertyModal,
}) => {
  const { isFetching, pagination, error } = applicationList
  const [queryOptions, setQueryOptions] = useState({ ...DEFAULT_QUERY })
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)

  const isFilterAll = queryOptions.filter?.status === FILTER_APP_PROGRESS_OPTIONS[0].value
  const hasQueryOption = !isFilterAll || !isEmpty(queryOptions.search)
  const hasAppList = applicationList.list.length > 0 || hasQueryOption
  const searchQueries = query.parse(queryOptions.search)
  const disabledTenantScreening = !!disabledFeatures[FEATURE.TENANT_SCREENING]?.disabledAt

  const { value: isCombinedAgreementIdvFeatureEnabled } = useFeatureGate(
    COMBINED_AGREEMENT_IDV_ADDRESS,
  )

  const {
    data: userInvitation,
    isFetched: isUserInvitationFetched,
    isLoading: isUserInvitationLoading,
  } = useGetMyUserInvitation()
  const isInvitedUser = !isEmpty(userInvitation?.result)

  useEffect(() => {
    const unlisten = history.listen(({ state, search }, action) => {
      if (action === 'POP' || (state && state.clearApplicationListSearch)) {
        setQueryOptions({
          ...DEFAULT_QUERY,
          filter: { ...DEFAULT_QUERY.filter },
        })
        const { page, filter, search: querySearch, sort } = query.parse(search)
        getApplicationList(page, filter, querySearch, sort, false)
      }
    })

    const { page, filter, search, sort, prvSource } = query.parse(location.search)
    if (validationQuery(page, filter, search, sort)) {
      window.location.href = '/not-found'
    }

    if (prvSource === SCREEN_NEW_TENANT) {
      actions.getUnSeenViewModals(POST_LOG_IN_ADVERTISE_MODAL_GROUP)
    }
    getApplicationList(page, filter, search, sort, false)

    tracker.trackEvent(EVENT_REQUEST_SCREENING.ENTER_SCREENING_MENU)

    return () => {
      unlisten()
    }
  }, [])

  const getApplicationList = (page, filter = {}, search, sort, pushHistory = true) => {
    const options = {
      ...queryOptions,
      page: page || queryOptions.page,
      filter: filter.status ? { ...filter } : { ...queryOptions.filter },
      search: isNil(search) ? queryOptions.search : search,
      sort: sort || queryOptions.sort,
    }
    setQueryOptions(options)

    const { propertyId } = match.params
    if (propertyId) {
      actions.getApplicationList(propertyId, options)
    }
    if (pushHistory) {
      addSearchParam(options)
    }
  }

  const validationQuery = (page, filter, search, sort) => {
    if (
      (page && isNaN(toNumber(page))) ||
      (filter &&
        filter.status &&
        filter.status !== 'all' &&
        findIndex(FILTER_APP_PROGRESS_OPTIONS, v => v.value === filter.status) === -1) ||
      (search && search.length > 50) ||
      (sort && indexOf(['asc', 'desc'], sort[Object.keys(sort)[0]]) < 0)
    ) {
      return true
    }
    return false
  }

  const handleChangePage = page => {
    getApplicationList(page)
  }

  const handleFilterBarStatus = ({ target: { value } }) => {
    getApplicationList(1, { status: value })
  }

  const handleSearch = search => {
    getApplicationList(1, {}, search)
  }

  const addSearchParam = ({ page, filter, search, sort }) => {
    history.push({
      pathname: location.pathname,
      search: query.stringify({ page, filter, search, sort }),
    })
  }

  /**
   * Event emit on clicking on each application
   * At the point there should be a logic to check whether the credit report had been opened or not
   * If it's not yet open, then the mortal should appear for opening the credit report
   * If it's already open, then push to the application page
   * @param {Object} app application object
   */
  const clickApplicationRow = (app = {}) => {
    const isCompletedIDV = get(profile, 'isVerified', false)
    if (!includes(APP_NOT_SUBMIT_STATUS, app.status)) {
      if (isCombinedAgreementIdvFeatureEnabled) {
        tracker.trackEvent(EVENT_REPORT.clickToViewApplicantInfo, {
          from: EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.START_VIEW_REPORT,
          completed_idv: isCompletedIDV,
          click_form_widget: location.search?.['click_from_widget'],
        })
      } else {
        tracker.trackEvent(EVENT_REPORT.clickViewReport, {
          click_from: EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.APPLICATION_LIST,
          click_from_widget: location.search?.['click_from_widget'],
        })
      }

      history.push(
        buildPath(SINGLE_APPLICATION, {
          propertyId: match.params.propertyId,
          // eslint-disable-next-line no-underscore-dangle
          rentalAppId: app._id,
        }),
      )
    }
  }

  const confirmDeactivateProperty = propertyId => {
    tracker.trackEvent(DEACTIVATE_PROPERTY, { click_from: APPLICATION_LIST })
    actions.deactivateProperty(propertyId)
  }

  const confirmReactivateProperty = propertyId => {
    tracker.trackEvent(REACTIVATE_PROPERTY, { click_from: APPLICATION_LIST })
    actions.reactivateProperty(propertyId)
  }

  const openFeatureToggleConfirmModal = (isEnable, source) => {
    actions.openTogglePropertyFeatureModal({
      feature: FEATURE.TENANT_SCREENING,
      type: isEnable ? FEATURE_STATUS.ENABLE : FEATURE_STATUS.DISABLED,
      source,
    })
  }

  const handleAddScreenNewTenant = () => {
    if (disabledFeatures[FEATURE.TENANT_SCREENING]) {
      openFeatureToggleConfirmModal(true, SOURCE.SCREEN_APP_LIST)
    } else {
      tracker.trackEvent(SCREEN_FROM_CURRENT_PROPERTY, {
        click_from: APPLICATION_LIST,
      })
      history.push(
        buildPath(
          PERMALINK_ACTIONS_ID,
          {
            propertyId: match.params.propertyId,
          },
          { screeningMethod: SCREENING_METHODS.EMAIL },
        ),
      )
    }
  }

  const handleOpenAcceptDenyModal = payload => {
    actions.openAcceptDenyModal(payload)
  }

  const handleSetupRentPayment = (propertyId, tenantEmail) => {
    tracker.trackEvent(RENT_PAYMENT.EVENT_NAME.CLICK_SETUP_RENT_PAYMENT, {
      click_from: EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.MENU_SCREENING_LISTING,
    })
    actions.continueToRentPayment({
      queryString: `?propertyId=${propertyId}&tenantEmail=${encodeURIComponent(tenantEmail)}`,
    })
  }

  const handleSetupLease = propertyId => {
    tracker.trackEvent(LEASE_AGREEMENTS.CREATE_LEASE, {
      click_from: EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.MENU_SCREENING_LISTING,
    })

    const url = buildPath(ESIGN_DASHBOARD_SELECT_METHODS, { propertyId })
    window.location.assign(url)
  }

  const handleGoBack = () => {
    history.push(PROPERTY)
  }

  const isLoading = isFetching || isUserInvitationLoading
  const isEmptyRecord = applicationList.list.length === 0
  const showDataEmptyState = !isLoading && isEmptyRecord
  const showApplicationsTable = !isLoading && (!isEmptyRecord || hasQueryOption)
  const showApplicationsFilter = !isEmptyRecord || hasQueryOption
  const isZeroState = !isFetching && isEmptyRecord && isUserInvitationFetched && !hasQueryOption

  const DisplayLoadingState = () =>
    isLoading ? (
      <CenterContent data-testid="property-applications-loading" overlay withBreadcrumb withFilter>
        <Loading />
      </CenterContent>
    ) : null

  const DisplayZeroState = () => {
    if (isZeroState) {
      return isInvitedUser ? (
        <OnboardingLandlordTSEmptyState hideTitle />
      ) : (
        <PropertyApplicationZeroState
          history={history}
          propertyId={property.propertyId}
          disabledTenantScreening={disabledTenantScreening}
        />
      )
    }
    return null
  }

  if (!isEmpty(error)) {
    return (
      <FailedToFetch
        noBreadcrumbMobile
        withPagination={hasAppList}
        title={ERRORS.APPLICATIONS.LISTING.TITLE}
        text={ERRORS.APPLICATIONS.LISTING.MESSAGE}
      />
    )
  }

  return (
    <Box id="applicationListPage" className="property-applications-list-container">
      <Level2Template
        data-testid="property-applications-title"
        title={property?.street}
        isMobile={isMobile}
        onClickBack={handleGoBack}
        breadcrumb={<Breadcrumb2023 />}
        tabs={<PropertyInnerTabs defaultTab="propertyInnerTabTenantScreening" />}
      />
      <DisplayLoadingState />
      <DisplayZeroState />
      {showApplicationsFilter && (
        <TenantScreeningFilterBar
          filterBarStatus={
            queryOptions.filter.status || SCREENING_TABLE_FILTER_BAR_OPTIONS[0].value
          }
          filterBarOptions={SCREENING_TABLE_FILTER_BAR_OPTIONS}
          handleFilterBarStatus={handleFilterBarStatus}
          handleFilterBarSearch={handleSearch}
          searchQueries={searchQueries}
        />
      )}
      {showApplicationsTable && (
        <TenantScreeningTable
          data={applicationList.list}
          isLoading={applicationList.isFetching}
          isAllowAcceptDeny={isAllowAcceptDeny}
          isAllowCarLease={isAllowCarLease}
          eventSource={{ clickFromWidget: searchQueries?.['click_from_widget'] }}
          onClickRentalSubmissionRow={clickApplicationRow}
          onOpenAcceptDenyModal={handleOpenAcceptDenyModal}
          onClickSetupLease={handleSetupLease}
          onClickSetupRentPayment={handleSetupRentPayment}
          hidePropertyAddress
          showDataEmptyState={showDataEmptyState}
        />
      )}
      {!isLoading && applicationList.list.length !== 0 && (
        <TenantScreeningPagination pagination={pagination} handleChangePage={handleChangePage} />
      )}
      <DeactivatePropertyModal
        data-testid="property-applications-deactivate-modal"
        id="deactivatePropertyModal"
        showModal={showDeactivatePropertyModal.isShow}
        propertyId={showDeactivatePropertyModal.propertyId}
        confirmDeactivateProperty={confirmDeactivateProperty}
        closeModal={() => actions.toggleDeactivatePropertyModal(false)}
      />
      <ReactivatePropertyModal
        id="reactivatePropertyModal"
        showModal={showReactivatePropertyModal.isShow}
        propertyId={showReactivatePropertyModal.propertyId}
        confirmReactivateProperty={confirmReactivateProperty}
        closeModal={() => actions.toggleReactivatePropertyModal(false)}
      />
      {!isZeroState && (
        <ScreenTenantFooter
          isMobile={isMobile}
          handleAddScreenNewTenant={handleAddScreenNewTenant}
        />
      )}
    </Box>
  )
}

export default compose(withTracker, withRouter, applicationListConnect)(PropertyApplicationsList)
