import React from 'react'
import { compose } from 'redux'
import { query } from '@rentspree/path'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import FontFaceObserver from 'fontfaceobserver'
import { DisableFeatureProvider } from '@rentspree/feature-toggle'

import { PRINT_REPORTS_PAGE } from 'constants/route'
import disableFeature from 'utils/feature-toggle/instance'
import {
  withReducer as withCheckWhiteListingReducer,
  withSaga as withCheckWhiteListingSaga,
} from 'containers/reports/screening-reports/with-open/connect'
import RouteReports from '../reports'
import { withConnect } from './connect'

export const PrintReportPage = ({ getProfile, isReportsReady, user, location, match, history }) => {
  const { search, pathname } = location
  const { autoPrint, print } = query.parse(search)

  const togglePrint = async () => {
    const isPrintPage = get(match, 'path', '').includes(PRINT_REPORTS_PAGE)

    if (autoPrint === 'true' && pathname.match(/^\/reports/) && isPrintPage) {
      const fontFamilies = [
        { family: 'Lato', testString: null },
        { family: 'Merriweather', testString: null },
        { family: 'Source Sans Pro', testString: null },
        { family: 'Font Awesome 6 Free', testString: '\uf240' },
      ]

      const fontObservers = fontFamilies.map(({ family, testString }) => {
        const obs = new FontFaceObserver(family)
        return obs.load(testString, 5000)
      })

      await Promise.all(fontObservers)

      setTimeout(() => {
        window.print()
      }, 2000)
    }
  }

  React.useEffect(() => {
    if (isEmpty(get(user, 'profile'))) getProfile()
  }, [])

  React.useEffect(() => {
    if (print === 'true') window.printMode = true
  }, [])

  React.useEffect(() => {
    if (isReportsReady) togglePrint()
  }, [isReportsReady])

  if (isEmpty(get(user, 'profile'))) return null

  return (
    <DisableFeatureProvider
      subdomain={window.location.host.split('.')[0] || 'rentspree'}
      disableFeature={disableFeature}
    >
      <RouteReports match={match} location={location} history={history} />
    </DisableFeatureProvider>
  )
}

export default compose(
  withCheckWhiteListingReducer,
  withCheckWhiteListingSaga,
  withRouter,
  withConnect,
)(PrintReportPage)
