import React from 'react'
import Box from '@mui/material/Box'
import { useMediaQuery } from 'v3/hooks/use-media-query'
import { PropertyListTable } from './table'
import { PropertyListPagination } from './pagination'
import { PropertyListTableMobile } from './table/table-mobile'
import { PropertyListFilterBar } from './filter-bar'

const PropertyListTableComponent = props => {
  const isMobile = useMediaQuery('(max-width: 1016px)')
  const { data = {}, isLoading = false, hasNoQueryOption = false } = props

  const showDataEmptyState = !isLoading && data.length === 0 && !hasNoQueryOption

  return (
    <Box data-testid="property-list-table-component">
      <PropertyListFilterBar {...props} />
      {isMobile ? (
        <PropertyListTableMobile showDataEmptyState={showDataEmptyState} {...props} />
      ) : (
        <PropertyListTable showDataEmptyState={showDataEmptyState} {...props} />
      )}
      {!showDataEmptyState && <PropertyListPagination {...props} />}
    </Box>
  )
}

export default PropertyListTableComponent
