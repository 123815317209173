/* eslint-disable import/no-cycle */
/* eslint-disable react/no-this-in-sfc */
import React from "react"
import styled from "styled-components"
import { buildPath } from "@rentspree/path"
import { PERMALINK_ACTIONS_ID } from 'constants/route'
import { PARTICIPANT_TYPE } from "containers/reports/constants"
import { MAIL_SUPPORT } from "../../../env"
import { RouterLink } from "../buttons/buttons"

import NO_SCORE from "../../images/icons/report-noscore.svg"
import REPORT_EXPIRED from "../../images/icons/report-expired.svg"
import REPORT_NOREPORT from "../../images/icons/report-noreport.svg"
import APP_NOAPP from "../../images/icons/app-noapp.svg"

export * from "./share/doc-components"

// language=SCSS prefix=dummy{ suffix=}
export const ReportWrapper = styled.div`
  padding: 10px;
  @media (max-width: 991px) {
    display: none;
  }
`

export const WrapperCreditReport = styled.div`
  width: 90%;
  max-width: 500px;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1.5;
  text-align: center;
  color: #333;
  img {
    height: 200px;
    width: auto;
    margin-bottom: 20px;
  }
`

const WrapperFrozenFile = styled.div`
  text-align: left;
  font-size: 0.9em;
  > h4 {
    text-align: center;
  }

  > ul {
    padding: 0 20px;
    line-height: 30px;
  }
`

export const FrozenFile = ({ agentName }) => (
  <WrapperCreditReport>
    <div>
      <img src={NO_SCORE} alt="Not enough information" />
    </div>
    <WrapperFrozenFile>
      <h4>Your credit is frozen.</h4>
      <p>
        Your completed application cannot be viewed yet by {agentName} because
        your credit file is frozen with TransUnion. To unfreeze your account and
        successfully complete your application, please follow the instructions
        below:
      </p>
      <ul>
        <li>
          1. Call the TransUnion security freeze line at{" "}
          <strong>(888) 909-8872</strong>.
        </li>
        <li>
          2. Request a “Global Lift” and provide your personal information.
        </li>
        <li>
          3. After successfully removing the freeze, please email us at{" "}
          <a href={`mailto:${MAIL_SUPPORT}`}>{MAIL_SUPPORT}</a> to regenerate
          your report.
        </li>
      </ul>
      <p>
        This process may take up to 72 hours. If you have any questions, contact{" "}
        <a href={`mailto:${MAIL_SUPPORT}`}>{MAIL_SUPPORT}</a> or (323) 515-7757.
      </p>
    </WrapperFrozenFile>
  </WrapperCreditReport>
)

// @TODO: migrate this component to report-not-available.js file with new configs
export const ReportExpired = ({ expiredDateString, IDMA }) => (
  <WrapperCreditReport>
    <div>
      <img src={REPORT_EXPIRED} alt="Report expired" />
    </div>
    {IDMA
      ? `The reports expired on ${expiredDateString}.  To ensure the security and accuracy of all information, reports are only available for 30 days after submission.`
      : `These screening reports expired on ${expiredDateString}. To keep all information up-to-date, screening reports are only valid for 30 days.`}
  </WrapperCreditReport>
)

export const CreditReportNotAvailable = ({ propertyId, participantType }) => (
  <WrapperCreditReport>
    <div>
      <img src={REPORT_NOREPORT} alt="Credit report not available" />
    </div>
    {participantType === PARTICIPANT_TYPE.PARTICIPANT ? (
      <>The credit report has not been shared with you for this applicant.</>
    ) : (
      <>
        You haven’t requested a credit report for this applicant. To request a
        credit report,&nbsp;
        <RouterLink
          id="noCreditReportScreenTenantsButton"
          onClick={() => this.handleClickScreenNewTenant()}
          to={buildPath(PERMALINK_ACTIONS_ID, { propertyId })}
          inlineLink>
          click here.
        </RouterLink>
      </>
    )}
  </WrapperCreditReport>
)

export const CriminalRecordNotAvailable = ({ propertyId }) => (
  <WrapperCreditReport>
    <div>
      <img src={REPORT_NOREPORT} alt="Criminal record not available" />
    </div>
    You haven’t requested a criminal background check for this applicant. To
    request a criminal background check,&nbsp;
    <RouterLink
      id="noCriminalRecordScreenTenantsButton"
      onClick={() => this.handleClickScreenNewTenant()}
      to={buildPath(PERMALINK_ACTIONS_ID, { propertyId })}
      inlineLink>
      click here.
    </RouterLink>
  </WrapperCreditReport>
)

export const EvictionRecordNotAvailable = ({ propertyId }) => (
  <WrapperCreditReport>
    <div>
      <img src={REPORT_NOREPORT} alt="Eviction record not available" />
    </div>
    You haven’t requested an eviction related proceedings for this applicant. To
    request an eviction related proceedings,&nbsp;
    <RouterLink
      id="noEvictionRecordScreenTenantsButton"
      onClick={() => this.handleClickScreenNewTenant()}
      to={buildPath(PERMALINK_ACTIONS_ID, { propertyId })}
      inlineLink>
      click here.
    </RouterLink>
  </WrapperCreditReport>
)

export const ApplicationNotAvailable = ({ propertyId }) => (
  <WrapperCreditReport>
    <div>
      <img src={APP_NOAPP} alt="Application not available" />
    </div>
    You haven’t requested an application from this applicant. To request an
    application,&nbsp;
    <RouterLink
      id="noApplicationScreenTenantsButton"
      onClick={() => this.handleClickScreenNewTenant()}
      to={buildPath(PERMALINK_ACTIONS_ID, { propertyId })}
      inlineLink>
      click here.
    </RouterLink>
  </WrapperCreditReport>
)
