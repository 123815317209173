export const EVENT_STEP = {
  START: 'start',
}

export const EVENT_LOCATION = {
  VIEW_REPORT: 'view report'
}

export const EVENT_MAIN_FEATURE = {
  IDENTITY_VERIFICATION: 'identity_verification',
}

export const CLICK_TEXT = "Access reports"