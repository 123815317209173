import { useFeatureGate } from '@statsig/react-bindings'
import React from 'react'

import { NEW_UI_TABLE_PROPERTY_APPLICATION } from 'utils/feature-flag/constants'
import ApplicationListOld from 'legacy/containers/application/application-list'
import PropertyApplicationsList from './property-applications-list'

export const PropertyApplications = props => {
  const { value: isFeatureGateEnabled } = useFeatureGate(NEW_UI_TABLE_PROPERTY_APPLICATION)

  if (isFeatureGateEnabled) {
    return <PropertyApplicationsList {...props} />
  }

  return <ApplicationListOld {...props} />
}

export default PropertyApplications
