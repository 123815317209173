import MoreVert from '@mui/icons-material/MoreVert'
import { Box } from '@mui/material'
import List from '@mui/material/List'
import Divider from '@rentspree/component-2023.components.atoms.divider'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import Menu from '@rentspree/component-2023.components.atoms.menu'
import MenuItem from '@rentspree/component-2023.components.atoms.menu-item'
import Sheet from '@rentspree/component-2023.components.templates.sheet'
import React, { useEffect } from 'react'

import { useScreenSize } from 'hooks/use-screen-size'

import { spotlight, removeSpotlight } from '../molecules/popper/spotlight'

import { PARTNER_ONBOARDING_FEATURES } from './const'

export const PropertyTableActions = ({
  id,
  items: groupOfItems = [],
  isHighlightScreenTenant = false,
  setIsHighlightScreenTenant = () => {},
  isNewUi,
}) => {
  const { isDesktop } = useScreenSize()

  const [isMenuOpened, setOpeningMenu] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { spotlightConfig: tenantSpotlightConfig } = PARTNER_ONBOARDING_FEATURES.TENANT_SCREENING
  const { spotlightConfig: dropdownButtonSpotlightConfig } =
    PARTNER_ONBOARDING_FEATURES.DROPDOWN_BUTTON
  const handleMenuButtonClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setOpeningMenu(true)
  }

  const handleCloseMenu = event => {
    event.stopPropagation()
    setAnchorEl(null)
    setOpeningMenu(false)
    removeSpotlight(
      document.querySelector(dropdownButtonSpotlightConfig.targetSelector),
      dropdownButtonSpotlightConfig,
    )
    removeSpotlight(
      document.querySelector(tenantSpotlightConfig.targetSelector),
      tenantSpotlightConfig,
    )
    setIsHighlightScreenTenant(false)
  }

  const stopPropagation = event => {
    event.stopPropagation()
  }

  const renderMenuItems = () =>
    groupOfItems.flatMap((group, i) => {
      const isLastGroup = i === groupOfItems.length - 1
      const elements = group
        .filter(item => !item.hidden)
        .map(item => (
          <MenuItem
            label={item.label}
            id={`${id}-${item.key}`}
            data-testid={`${id}-${item.key}`}
            key={`${item.key}`}
            disabled={!item.onClick}
            onClick={e => {
              handleCloseMenu(e)
              if (item.onClick) item.onClick()
            }}
            sx={{
              ...(isDesktop && { minWidth: '264px' }),
              minHeight: 'auto',
              height: '40px',
            }}
          />
        ))
      if (!isLastGroup) {
        elements.push(<Divider light component="li" sx={{ margin: '0 12px' }} />)
      }
      return elements
    })

  useEffect(() => {
    if (isHighlightScreenTenant && isDesktop) {
      const dropDownButton = document.querySelector(dropdownButtonSpotlightConfig.targetSelector)
      dropDownButton.click()
      setOpeningMenu(true)
    }
  }, [isHighlightScreenTenant, isDesktop])

  useEffect(() => {
    if (!isHighlightScreenTenant) return
    // NOTE: set timeout for wait component to render before highlight
    setTimeout(() => {
      if (!isDesktop) {
        const dropDownButton = document.querySelector(dropdownButtonSpotlightConfig.targetSelector)
        spotlight(dropDownButton, dropdownButtonSpotlightConfig)
      }

      if (isMenuOpened) {
        const tenantScreeningItem = document.querySelector(tenantSpotlightConfig.targetSelector)
        spotlight(tenantScreeningItem, tenantSpotlightConfig)
      }
    }, 0)
  }, [isHighlightScreenTenant, isDesktop, isMenuOpened])

  return (
    <>
      <IconButton
        id={id}
        data-testid={id}
        onClick={handleMenuButtonClick}
        color="black"
        size="medium"
        sx={{ ...(isNewUi && { marginTop: '-4px' }) }}
      >
        <MoreVert />
      </IconButton>
      {isDesktop ? (
        <Menu
          id={`${id}-menu-items`}
          data-testid={`${id}-menu-items`}
          key={`${id}-menu-items`}
          autoFocus={false}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpened}
          onClose={handleCloseMenu}
          onClick={stopPropagation}
        >
          {renderMenuItems()}
        </Menu>
      ) : (
        <Sheet
          id={`${id}-menu-items`}
          data-testid={`${id}-menu-items`}
          key={`${id}-menu-items`}
          open={isMenuOpened}
          onClose={handleCloseMenu}
          onClick={stopPropagation}
        >
          <Box
            sx={{
              padding: '48px 8px 0 8px',
            }}
          >
            <List>{renderMenuItems()}</List>
          </Box>
        </Sheet>
      )}
    </>
  )
}
