import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import S18 from '@rentspree/component-v2/dist/typography/s18'
import B16 from '@rentspree/component-v2/dist/typography/b16'

import { Li } from 'components/atoms/list'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { Box } from '@mui/material'

const Container = styled.div`
  font-family: Source Sans Pro;
`

// [TECH-22068] TODO: clean up feature flag and use new ui
const SubtitleWithList = ({ subtitle, list, isNewUiPropertyTable }) => {
  if (isNewUiPropertyTable) {
    return (
      <Box>
        <Typography variant="body-large">{subtitle}</Typography>
        <Typography variant="body-medium">
          {list.map(item => (
            <Li key={item}>{item}</Li>
          ))}
        </Typography>
      </Box>
    )
  }
  return (
    <Container>
      <S18>{subtitle}</S18>
      <B16>
        {list.map(item => (
          <Li key={item}>{item}</Li>
        ))}
      </B16>
    </Container>
  )
}

export default SubtitleWithList

SubtitleWithList.propTypes = {
  subtitle: propTypes.node,
  list: propTypes.array,
}

SubtitleWithList.defaultProps = {
  list: [],
}
