import React from 'react'
import { generateAddress } from '@rentspree/helper'
import { clsx } from 'clsx'

import { Typography } from '@rentspree/component-2023.components.atoms.typography'
import { Box } from '@mui/material'
import { SCREEN_WITHOUT_PROPERTY } from '../constants'

import './table-cell-applicant.scss'

export const ScreeningTableCellApplicant = ({ rental = {}, hidePropertyAddress = false }) => {
  const { firstName, lastName, email, propertyAddress } = rental
  const address = (propertyAddress && generateAddress(propertyAddress)) || ''

  const displayName = firstName ? `${firstName} ${lastName}` : email

  const mainClass = 'screening-table-body-cell-applicant-property'
  const lighterColorTextClass = address ? '' : 'subtitle-text-light'
  const combinedClass = clsx(mainClass, lighterColorTextClass)

  return (
    <Box className="screening-table-body-cell-applicant">
      <Typography variant="body-large" sx={{ fontWeight: '500' }}>
        {displayName}
      </Typography>
      {!hidePropertyAddress && (
        <Typography className={combinedClass} variant="body" title={address}>
          {address || SCREEN_WITHOUT_PROPERTY}
        </Typography>
      )}
    </Box>
  )
}
