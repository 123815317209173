import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { compose } from 'redux'
import { Div } from 'components/layout/main'
import {
  LEASE_AGREEMENT_DETAILS,
  LEASE_AGREEMENT_LIST,
  LEASE_AGREEMENT_UNSUPPORT_PAGE,
  LISTING_MANAGE,
  PROPERTY_OVERVIEW_PAGE,
  RENT_ESTIMATE_PROPERTY,
  RENT_PAYMENT_ID_HISTORY_PAGE,
  RENT_PAYMENT_ID_INFO_PAGE,
  RENT_PAYMENT_ID_PAGE,
  RENT_PAYMENT_ID_TRANSACTION_PAGE,
  RENT_PAYMENT_PROPERTY_PAGE,
  RENTER_INSURANCE_PAGE,
  RENTER_INSURANCE_DETAIL,
  SINGLE_APPLICATION,
  SINGLE_PROPERTY_APPLICATION_LIST,
} from 'constants/route'
import AppDetailContainer from 'legacy/containers/application/application-detail'
import RentEstimate from 'containers/rent-estimate/loadable'
import ListingManagement from 'containers/listing-management/loadable'

import LeaseAgreementDetailsContainer from 'containers/lease-agreement-details'
import UnSupportPage from 'containers/unsupport-page'
import LeaseAgreementList from 'containers/lease-agreement-list/loadable'
import OverviewContainer from 'containers/overview/loadable'
import TogglePropertyFeatureModal from 'containers/property/toggle-property-feature'
import RenterInsuranceContainer from 'containers/renter-insurance'
import RentersInsuranceDetail from 'containers/renters-insurance-details'
import { AP_BANNER_HEIGHT } from 'components/organisms/agent-profile-banner/constants'
import { LISTING_STATUS } from 'containers/create-listing/const'
import { LISTING_MANAGEMENT_SOURCE } from 'containers/constants'
import { parse } from '@rentspree/path'
import CreateAssociationContactModal from 'containers/overview/association-contact/create-association-contact-modal'
import { RentPaymentRouter } from 'v3/containers/rent-payment/router'
import PropertyApplications from 'containers/property-applications'
import { withConnect } from './connect'
import { AgentProfileBannerContainer } from './agent-profile-banner'
import { AbilityContext } from '../reports/with-pro-report-available/ability-context'
import { ability } from '../reports/with-pro-report-available/ability'

export class ListingRouteContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowMore: false,
      parentDivStyle: {},
    }
  }

  componentDidMount() {
    const {
      actions,
      match: {
        params: { propertyId },
      },
    } = this.props
    if (propertyId) actions.getProperty(propertyId)
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.clearProperty()
    window.removeEventListener('resize', this.updateDimensions)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.match?.params?.actionType !== 'listing') {
        this.resetParentDivStyle()
      }
    }
  }

  resetParentDivStyle() {
    this.setState({ parentDivStyle: {} })
  }

  onClickMoreMenu = key => () => {
    const { isShowMore } = this.state
    if (key === 'showMore') {
      this.setState({ isShowMore: !isShowMore })
    } else if (isShowMore) {
      this.setState({ isShowMore: false })
    }
  }

  updateDimensions = () => {
    const { isShowMore } = this.state
    if (window.innerWidth >= 979 && isShowMore) {
      this.setState({ isShowMore: false })
    }
  }

  isValidSource = () => {
    const queryString = parse(this.props.history.location.search)
    return queryString.source === LISTING_MANAGEMENT_SOURCE.SHARE_LISTING_PAGE
  }

  isValidListing = () => this.props.property?.listing?.status === LISTING_STATUS.PUBLISHED

  renderApBanner = () =>
    this.isValidSource() && this.isValidListing() ? (
      <AgentProfileBannerContainer
        displayBanner={() =>
          this.setState({
            parentDivStyle: { mb: -AP_BANNER_HEIGHT, mMargin: '0' },
          })
        }
        hideBanner={() => {
          this.resetParentDivStyle()
        }}
      />
    ) : null

  render() {
    const { isShowMore } = this.state

    return (
      <Div>
        <Div flex listingWrapper justifyContent="center" {...this.state.parentDivStyle}>
          <TogglePropertyFeatureModal propertyId={this.props.match?.params?.propertyId} />
          <Div flexGrow={1} isHide={isShowMore}>
            <CreateAssociationContactModal propertyId={this.props.match?.params?.propertyId} />
            <Switch>
              <Route path={LISTING_MANAGE} component={ListingManagement} />

              <Route
                exact
                path={SINGLE_PROPERTY_APPLICATION_LIST}
                component={PropertyApplications}
              />
              <Route exact path={RENT_ESTIMATE_PROPERTY} component={RentEstimate} />
              <Route
                path={SINGLE_APPLICATION}
                render={() => (
                  <AbilityContext.Provider value={ability}>
                    <AppDetailContainer {...this.props} />
                  </AbilityContext.Provider>
                )}
              />
              <Route exact path={PROPERTY_OVERVIEW_PAGE} component={OverviewContainer} />
              <Route exact path={LEASE_AGREEMENT_LIST} component={LeaseAgreementList} />
              <Route
                exact
                path={LEASE_AGREEMENT_DETAILS}
                component={LeaseAgreementDetailsContainer}
              />
              <Route exact path={LEASE_AGREEMENT_UNSUPPORT_PAGE} component={UnSupportPage} />
              <Route exact path={RENTER_INSURANCE_PAGE} component={RenterInsuranceContainer} />
              <Route exact path={RENTER_INSURANCE_DETAIL} component={RentersInsuranceDetail} />

              {/* ===================== Start of Rent Payment - Property Related ===================== */}
              <Route
                exact
                path={[
                  RENT_PAYMENT_PROPERTY_PAGE,
                  RENT_PAYMENT_ID_PAGE,
                  // TODO: Remove when clean up new_ia_navbar
                  RENT_PAYMENT_ID_TRANSACTION_PAGE,
                  RENT_PAYMENT_ID_HISTORY_PAGE,
                  RENT_PAYMENT_ID_INFO_PAGE,
                ]}
                component={RentPaymentRouter}
              />
              {/* ===================== End of Rent Payment - Property Related ===================== */}

              <Route
                render={() => {
                  window.location.href = '/not-found'
                }}
              />
            </Switch>
          </Div>
        </Div>
        {/* <Switch>
          <Route id="listing-manage-banner" path={LISTING_MANAGE} render={this.renderApBanner} />
        </Switch> */}
      </Div>
    )
  }
}

export default compose(withConnect)(ListingRouteContainer)
