import Button from '@rentspree/component-2023.components.atoms.button'
import PopperContainer from '@rentspree/component-2023.components.organisms.popper'
import PopperActions from '@rentspree/component-2023.components.organisms.popper-actions'
import PopperContent from '@rentspree/component-2023.components.organisms.popper-content'
import PopperHeader from '@rentspree/component-2023.components.organisms.popper-header'
import React, { useEffect, useMemo } from 'react'

import { spotlight, removeSpotlight, selectElementToSpotlight } from './spotlight'

/**
 * Popper the wrapper component for the popper component 2023
 * with business logic.
 *
 * @param {object} props - Popper properties
 * @param {boolean} props.open - show popper state
 * @param {import('react').MouseEventHandler<SVGSVGElement>} props.onClose - on click close button
 * @param {MouseEventHandler<HTMLAnchorElement>} props.onClickCTA - on click CTA
 * @param {HTMLElement} props.anchorElement - target element that popper will be attached
 * @param {string} props.title - the popper title
 * @param {ReactNode} props.content - the popper content
 * @param {string} [props.ctaText] - the CTA text (default: 'Next')
 * @param {number | string} [props.currentStep] - the current step
 * @param {number | string} [props.totalStep] - the total step
 * @param {number | string} [props.width] - the popper width
 * @param {import('@mui/material').PopperPlacementType} props.placement - position of the popper by the anchorEl
 * @param {import('./spotlight').SpotlightConfig} props.spotlightConfig - the anchorEl spotlight config
 * @returns {import('react').ReactElement}
 */
export const Popper = ({
  open,
  onClose,
  onClickCTA,
  anchorElement,
  title,
  content,
  hasCta = true,
  ctaText = 'Next',
  currentStep = 1,
  totalStep = 1,
  width = 'auto',
  margin,
  placement,
  spotlightConfig = { enabled: false },
  styles,
  ...props
}) => {
  const hasStep = currentStep && totalStep
  const stepWording = hasStep ? `${currentStep} of ${totalStep}` : undefined
  const spotlightElement = useMemo(
    () =>
      selectElementToSpotlight({
        elementSelector: spotlightConfig?.targetSelector,
        anchorElement,
      }),
    [anchorElement?.id, spotlightConfig?.targetSelector],
  )

  const cleanUpStyle = () => {
    if (anchorElement) {
      removeSpotlight(spotlightElement, spotlightConfig)
    }
  }

  useEffect(() => {
    if (anchorElement) {
      if (open) {
        spotlight(spotlightElement, spotlightConfig)
        return cleanUpStyle
      }
    }

    return () => {}
  }, [anchorElement, spotlightConfig, open])

  let handleClose
  if (onClose) {
    handleClose = event => {
      onClose(event)
    }
  }

  const handleClickCTA = event => {
    if (onClickCTA) {
      onClickCTA(event)
    }
  }

  return (
    <PopperContainer
      open={open}
      anchorEl={anchorElement}
      placement={placement}
      transition
      sx={{
        width,
        margin: `${margin} !important`,
        ...styles,
      }}
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              padding: 8,
            },
          },
        ],
      }}
      {...props}
    >
      <PopperHeader stepText={stepWording} handleOnClose={handleClose}>
        {title}
      </PopperHeader>
      <PopperContent>{content}</PopperContent>
      {hasCta && (
        <PopperActions>
          <Button
            data-testid="popper-cta-button"
            onClick={handleClickCTA}
            variant="text"
            size="small"
            color="inversePrimary"
          >
            {ctaText}
          </Button>
        </PopperActions>
      )}
    </PopperContainer>
  )
}
