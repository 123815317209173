import React from 'react'
import styled from 'styled-components'

import { IconColumn, IconColumnText } from 'legacy/components/table/table-style'
import { Span } from 'components/typography/text-style'
import { renderProperty } from 'components/tenant-screening-table/column-property'

import ReportStatusIcon from 'components/tenant-screening-table/report-status-icon'
import { COLOR } from 'styles/settings'
import { STATUS_WITH_HIGHLIGHTED_RENTER_NAME } from './constants'

export const AppName = styled.div`
  > span {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
`

const PropertyAddress = styled(Span)`
  max-width: 256px;
`

const DisplayName = styled.div`
  ${({ highlighted }) => highlighted && `color: ${COLOR.fontBlue};`}
  ${({ highlighted }) => highlighted && `font-weight: 600;`}
`

const getDisplayName = app => (app.firstName ? `${app.firstName} ${app.lastName}` : app.email)

const ColumnName = ({
  app = {},
  index,
  participantType,
  applicationProgress,
  isShowPropertyAddress,
}) => {
  const { status, creditReport, propertyAddress } = app

  const displayName = getDisplayName(app)
  const shouldHighlightApplicantName = STATUS_WITH_HIGHLIGHTED_RENTER_NAME.includes(app.status)

  return (
    <IconColumn id={`applicationItem${index}`}>
      <div>
        <IconColumnText>
          <AppName>
            <DisplayName highlighted={shouldHighlightApplicantName}>{displayName}</DisplayName>
            {isShowPropertyAddress && (
              <PropertyAddress margin="5px 0">
                {renderProperty({
                  status,
                  propertyAddress,
                })}
              </PropertyAddress>
            )}
            <Span>{applicationProgress}</Span>
            <ReportStatusIcon
              creditReport={creditReport}
              appStatus={status}
              participantType={participantType}
            />
          </AppName>
        </IconColumnText>
      </div>
    </IconColumn>
  )
}

export default ColumnName
